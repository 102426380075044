import {
  actionCreator,
  requestBlueprint,
  successBlueprint,
  failureBlueprint,
  requestStateBlueprint
} from './Utils'
import moment from 'moment'
import { RESET } from './Root'

// Types
export const STATIONS_REQUEST = 'STATIONS_REQUEST'
export const STATIONS_SUCCESS = 'STATIONS_SUCCESS'
export const STATIONS_FAILURE = 'STATIONS_FAILURE'

// Actions
export default {
  // login
  stationsRequest: actionCreator(STATIONS_REQUEST),
  stationsSuccess: actionCreator(STATIONS_SUCCESS),
  stationsFailure: actionCreator(STATIONS_FAILURE)
}

// Initial state
export const INITIAL_STATE = {
  ...requestStateBlueprint,
  data: [],
  timestamp: null
}

// Reducers
const request = state => Object.assign({}, state, { ...requestBlueprint })

// login
export const stationsSuccess = (state, data) => {
  return Object.assign({}, state, {
    ...successBlueprint,
    error: false,
    errorCode: null,
    errorMessage: null,
    data,
    timestamp: moment.utc()
  })
}

const stationsFailure = (state, { code, message }) => {
  return Object.assign({}, state, {
    ...failureBlueprint,
    isAuthenticated: false,
    error: true,
    errorCode: code,
    errorMessage: message,
    data: [],
    timestamp: moment.utc()
  })
}

export function reducer (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case STATIONS_REQUEST:
      return request(state)
    case STATIONS_SUCCESS:
      return stationsSuccess(state, payload)
    case STATIONS_FAILURE:
      return stationsFailure(state, payload)
    case RESET:
      return INITIAL_STATE
    default:
      return state
  }
}
