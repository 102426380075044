import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import StationPropTypes from '../../Types/Station'
import { SDContainer, SDRow, SDSidebar, SDContent } from '../StationDetail'
import { Divider } from 'semantic-ui-react'
import Sketch from '../Sketch'
import CanvasObjects from '../CanvasObjects'
import SaveCanvasButton from '../SaveCanvasButton'
import CanvasActions from '../../Redux/Canvas'
import Toolbox from '../Toolbox'
import { withLoader } from '../../HOC/Loader'
import { request } from '../../Services/Request'
import styles from './StationTrespassing.module.scss'

const StationTrespassing = ({ station }) => {
  const dispatch = useDispatch()
  const photo = useSelector(state => state.photos.data[station.id])
  const fetching = useSelector(state => state.photos.fetching)
  const [isLoadingShapes, setIsLoadingShapes] = useState(false)

  useEffect(() => {
    setIsLoadingShapes(true)
    request(
      'noTrespassingZones',
      [station.id],
      'Si è verificato un errore nel recuperare le no trespassing zones: {error}',
      response => {
        const s = Array.isArray(response.data) ? response.data : JSON.parse(response.data)
        dispatch(CanvasActions.initShapes(s))
        setIsLoadingShapes(false)
      }
    )
  }, [])

  return (
    <SDContainer>
      <SDRow>
        <SDSidebar station={station}>
          <Divider className={styles.divider} />
          <Toolbox />
          <CanvasObjects />
          <SaveCanvasButton stationId={station.id} />
        </SDSidebar>
        <SDContent>
          {withLoader(
            <Sketch photo={photo} />,
            (fetching && !photo) || isLoadingShapes
          )}
        </SDContent>
      </SDRow>
    </SDContainer>
  )
}

StationTrespassing.propTypes = {
  station: StationPropTypes
}

export default StationTrespassing
