import React from 'react'
import { Link } from 'react-router-dom'
import config from '../../Config'
import styles from './Welcome.module.scss'

/**
 * Welcome Component
 *
 * @param {Object} props
 */

const Welcome = props => {
  return (
    <div className={styles.container}>
      <Link to={config.urls.home}>
        Welcome to
        <br />
        <strong>VALE</strong>
        <br />
      </Link>
    </div>
  )
}

export default Welcome
