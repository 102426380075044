import React from 'react'
import PropTypes from 'prop-types'
import StationPropTypes from '../../Types/Station'
import { Modal, Icon, Button } from 'semantic-ui-react'

const StationModal = ({ station, isOpen, onClose, onSubmit }) => (
  <Modal open={isOpen} onClose={onClose} size='small'>
    <Modal.Header>
      {station.name}
    </Modal.Header>
    <Modal.Content>
      <p><Icon name='map pin' /> {station.location.latitude},{station.location.longitude}</p>
      {station.address && <address>{station.address}</address>}
      {station.description && <p>{station.description}</p>}
    </Modal.Content>
    <Modal.Actions>
      <Button color='blue' onClick={onClose}>
        <Icon name='remove' /> Chiudi
      </Button>
      <Button color='green' onClick={onSubmit} icon>
        <Icon name='angle right' /> Dettaglio
      </Button>
    </Modal.Actions>
  </Modal>
)

StationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  station: StationPropTypes
}

export default StationModal
