import { call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import SegmentationsActions from '../Redux/Segmentations'
import { getResponseStringError } from '../Utils/Request'

export function * fetchSegmentation (api, { payload: stationId }) {
  // request
  const response = yield call(api.segmentation, stationId)

  // success?
  if (response.ok) {
    yield put(
      SegmentationsActions.segmentationSuccess({
        stationId,
        data: response.data.base64image
      })
    )
  } else {
    toast('Si è verificato un errore nel recuperare le segmentazioni: ' + getResponseStringError(response), {
      type: 'error'
    })
    yield put(
      SegmentationsActions.segmentationFailure({
        code: response.status,
        message: getResponseStringError(response)
      })
    )
  }
}

export function * fetchSegmentationWeights (api, { payload: stationId }) {
  // request
  const response = yield call(api.segmentationWeights, stationId)

  // success?
  if (response.ok) {
    yield put(
      SegmentationsActions.segmentationWeightsSuccess({
        stationId,
        data: response.data.segmentation
      })
    )
  } else {
    toast(
      'Si è verificato un errore nel recuperare i pesi delle segmentazioni: ' + getResponseStringError(response),
      { type: 'error' }
    )
    yield put(
      SegmentationsActions.segmentationFailure({
        code: response.status,
        message:
          getResponseStringError(response)
      })
    )
  }
}
