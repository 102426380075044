import React, { useState, useCallback } from 'react'
import Map from '../../Components/Map'
import BaseLayout from '../../Layouts/BaseLayout'
import StationModal from '../../Components/StationModal'
import history from '../../history'
import config from '../../Config'
import styles from './HomeView.module.css'

const HomeView = props => {
  const [station, setStation] = useState(null)
  const openStation = useCallback(station => setStation(station), [])
  const closeStation = useCallback(() => setStation(null), [])
  const gotoStation = useCallback(() => history.push(config.urls.stationDetail.replace(':id', station.id)), [station])

  return (
    <BaseLayout wrapperStyle={styles.homeView}>
      <Map onMarkerClick={openStation} />
      {station && (
        <StationModal
          station={station}
          isOpen
          onClose={closeStation}
          onSubmit={gotoStation}
        />
      )}
    </BaseLayout>
  )
}

export default HomeView
