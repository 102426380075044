import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Navbar from '../Components/Navbar'
import StationsModal from '../Components/StationsModal'
import Footer from '../Components/Footer'

import styles from './BaseLayout.module.scss'

const BaseLayout = props => {
  const [stationsModalIsOpen, setStationsModalIsOpen] = useState(false)
  const handleCloseStationsModal = useCallback(() => setStationsModalIsOpen(false), [])
  const handleVoiceClick = useCallback(voice => {
    if (voice === 'stations') {
      setStationsModalIsOpen(true)
    }
  }, [])

  return (
    <div className={`${styles.wrapper} ${props.wrapperStyle}`}>
      <Navbar onVoiceClick={handleVoiceClick} />
      <StationsModal isOpen={stationsModalIsOpen} onClose={handleCloseStationsModal} />
      <main>
        {props.children}
      </main>
      <Footer />
    </div>
  )
}

BaseLayout.defaultProps = {
  wrapperStyle: 'wrapper'
}

BaseLayout.propTypes = {
  wrapperStyle: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default BaseLayout
