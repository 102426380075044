import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import StationPropTypes from '../../Types/Station'
import { SDContainer, SDRow, SDSidebar, SDContent } from '../StationDetail'
import UiActions from '../../Redux/Ui'
import GrowthRatesActions from '../../Redux/GrowthRates'
import MonthSelect from '../MonthSelect'
import { Image, Divider, Dropdown, Input } from 'semantic-ui-react'
import { withLoader } from '../../HOC/Loader'
import moment from 'moment'
import styles from './StationSegmentation.module.scss'

const StationGrowthRate = ({ station }) => {
  const dispatch = useDispatch()
  let ref = useSelector(state => state.ui.growthRateRefs[station.id])
  const photo = useSelector(state => state.photos.data[station.id])
  const fetching = useSelector(state => state.photos.fetching)
  const overlay = useSelector(state =>
    state.growthRates.data[station.id]
      ? state.growthRates.data[station.id].image
      : null
  )
  const legend = useSelector(state =>
    state.growthRates.data[station.id]
      ? state.growthRates.data[station.id].legend
      : null
  )

  if (!ref) {
    ref = moment().format('YYYY')
  }

  const analysisMode = /\d{4}/.test(ref) ? 'rel' : 'abs'

  const handleRefChange = value => {
    let ref
    if (value === 'abs' && analysisMode === 'rel') {
      ref = moment().format('MMMM').toLowerCase()
    } else if (value === 'rel' && analysisMode === 'abs') {
      ref = moment().format('YYYY')
    } else {
      ref = value
    }
    dispatch(UiActions.setGrowthRateRef({ stationId: station.id, ref }))
    dispatch(GrowthRatesActions.growthRateRequest({ stationId: station.id, growthRateRef: ref }))
  }

  const referenceInput = () => {
    if (analysisMode === 'rel') {
      return <Input type='number' value={ref} onChange={(e, { value }) => handleRefChange(value)} />
    } else {
      return (
        <MonthSelect
          value={ref}
          onChange={handleRefChange}
        />
      )
    }
  }

  return (
    <SDContainer>
      <SDRow>
        <SDSidebar station={station}>
          <Divider className={styles.divider} />
          <h3 className={styles.systemMonitor}>Sistema di monitoraggio</h3>
          <Dropdown
            placeholder='modalità di analisi'
            fluid
            selection
            value={analysisMode}
            onChange={(e, { value }) => handleRefChange(value)}
            options={[
              { key: 'abs', value: 'abs', text: 'analisi crescita assoluta' },
              { key: 'rel', value: 'rel', text: 'analisi crescita relativa' }
            ]}
          />
          <label className={styles.label}>Periodo di riferimento</label>
          {referenceInput()}
          {withLoader(
            <Image
              className={styles.legend}
              src={`data:image/jpeg;charset=utf-8;base64,${legend}`}
            />,
            fetching
          )}
        </SDSidebar>
        <SDContent>
          {withLoader(
            <div className={styles.growthWrapper}>
              <Image
                fluid
                src={`data:image/jpeg;charset=utf-8;base64,${photo}`}
              />
              {overlay && (
                <Image
                  className={styles.overlay}
                  fluid
                  src={`data:image/jpeg;charset=utf-8;base64,${overlay}`}
                />
              )}
            </div>,
            !overlay
          )}
        </SDContent>
      </SDRow>
    </SDContainer>
  )
}

StationGrowthRate.propTypes = {
  station: StationPropTypes
}

export default StationGrowthRate
