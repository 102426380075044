import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Table, Icon, Input, Pagination } from 'semantic-ui-react'
import { usePagination, useSorting, useFullTextSearch } from '../../Hooks'
import { Link } from "react-router-dom"
import config from '../../Config'
import moment from 'moment'
import _ from 'lodash'
import styles from './StationsTable.module.scss'

const StationsTable = props => {
  // data
  const stations = useSelector(state => state.stations.data)
  const refTimestamp = moment(useSelector(state => state.stations.timestamp))
    .clone()
    .subtract(10, 'minutes')
  // filtering
  const [search, setSearch, filteredStations] = useFullTextSearch(
    stations.slice(),
    ['name']
  )
  // sorting
  const [sort, setSort, sortedStations] = useSorting(
    filteredStations,
    'name',
    'asc'
  )
  // pagination
  const PER_PAGE = 10
  const [page, setPage, paginatedStations] = usePagination(sortedStations, PER_PAGE)
  // handlers
  const handleSearchChange = useCallback(
    (event, { value }) => { setSearch(value); setPage(1) },
    [setSearch, setPage]
  )
  const handlePageChange = useCallback(
    (e, { activePage }) => setPage(activePage),
    [setPage]
  )

  return (
    <>
      <Input
        value={search}
        size='small'
        placeholder='Cerca...'
        onChange={handleSearchChange}
        icon='search'
      />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              className={styles.stationCell}
              style={{ cursor: 'pointer' }}
              onClick={() =>
                setSort({
                  field: 'name',
                  direction: sort.direction === 'asc' ? 'desc' : 'asc'
                })}
            >
              Stazione
              {sort.field === 'name' && (
                <Icon className={styles.sortIcon} name={sort.direction === 'asc' ? 'angle up' : 'angle down'} />
              )}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Condizioni</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Temperatura</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Stato</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {paginatedStations.map(s => {
            const positive = moment.utc(s.last_data_ts).isAfter(refTimestamp)
            return (
              <Table.Row
                key={'station-' + s.id}
                positive={positive}
                negative={!positive}
              >
                <Table.Cell
                  className={styles.stationCell}
                >
                  <Link to={config.urls.stationDetail.replace(':id', s.id)} onClick={props.onStationClick}>{s.name}</Link>
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  <i
                    className={`wi ${
                      config.wiMap[s.precipitation_type.toLowerCase()]
                    }`}
                  />
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  {s.temperature ? (
                    _.round(s.temperature, 2) + ' °C'
                  ) : (
                    <i className='wi wi-na' />
                  )}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                  <Icon name={positive ? 'heartbeat' : 'ambulance'} />
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      <div style={{ maxWidth: '100%', overflow: 'auto' }}>
        <Pagination
          activePage={page}
          onPageChange={handlePageChange}
          totalPages={Math.ceil(filteredStations.length / PER_PAGE)}
        />
      </div>
    </>
  )
}

StationsTable.propTypes = {
  onStationClick: PropTypes.func.isRequired
}

export default StationsTable
