import { call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import GrowthRatesActions from '../Redux/GrowthRates'
import { getResponseStringError } from '../Utils/Request'

export function * fetchGrowthRate (
  api,
  { payload: { stationId, growthRateRef } }
) {
  // ask for legend too
  yield put(
    GrowthRatesActions.growthRateLegendRequest({
      stationId,
      growthRateRef
    })
  )
  // request
  const response = yield call(api.growthRate, stationId, growthRateRef)

  // success?
  if (response.ok) {
    yield put(
      GrowthRatesActions.growthRateSuccess({
        stationId: stationId,
        data: response.data.base64image
      })
    )
  } else {
    toast(
      'Si è verificato un errore nel recuperare il tasso di crescita: ' +
        getResponseStringError(response),
      { type: 'error' }
    )
    yield put(
      GrowthRatesActions.growthRateFailure({
        code: response.status,
        message: getResponseStringError(response)
      })
    )
  }
}

export function * fetchGrowthRateLegend (
  api,
  { payload: { stationId, growthRateRef } }
) {
  // request
  const response = yield call(api.growthRateLegend, stationId, growthRateRef)

  // success?
  if (response.ok) {
    yield put(
      GrowthRatesActions.growthRateLegendSuccess({
        stationId,
        data: response.data.base64image
      })
    )
  } else {
    toast(
      'Si è verificato un errore nel recuperare la legenda del tasso di crescita: ' +
        getResponseStringError(response),
      { type: 'error' }
    )
    yield put(
      GrowthRatesActions.growthRateFailure({
        code: response.status,
        message: getResponseStringError(response)
      })
    )
  }
}
