import React from 'react'
import { useSelector } from 'react-redux'
import StationPropTypes from '../../Types/Station'
import { SDContainer, SDRow, SDSidebar, SDContent } from '../StationDetail'
import { Image } from 'semantic-ui-react'
import { withLoader } from '../../HOC/Loader'

const StationHome = ({ station }) => {
  const photo = useSelector(state => state.photos.data[station.id])
  const fetching = useSelector(state => state.photos.fetching)

  return (
    <SDContainer>
      <SDRow>
        <SDSidebar station={station} />
        <SDContent>
          {withLoader(
            <Image fluid src={`data:image/jpeg;charset=utf-8;base64,${photo}`} alt='ultima immagine' />,
            fetching && !photo
          )}
        </SDContent>
      </SDRow>
    </SDContainer>
  )
}

StationHome.propTypes = {
  station: StationPropTypes
}

export default StationHome
