import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'semantic-ui-react'

const MonthSelect = props => {
  return (
    <Dropdown
      fluid
      selection
      value={props.value}
      onChange={(e, { value }) => props.onChange(value)}
      options={[
        { key: 'jan', value: 'january', text: 'gennaio' },
        { key: 'feb', value: 'february', text: 'febbraio' },
        { key: 'mar', value: 'march', text: 'marzo' },
        { key: 'apr', value: 'april', text: 'aprile' },
        { key: 'may', value: 'may', text: 'maggio' },
        { key: 'jun', value: 'june', text: 'giugno' },
        { key: 'jul', value: 'july', text: 'luglio' },
        { key: 'aug', value: 'august', text: 'agosto' },
        { key: 'sep', value: 'september', text: 'settembre' },
        { key: 'oct', value: 'october', text: 'ottobre' },
        { key: 'nov', value: 'november', text: 'novembre' },
        { key: 'dec', value: 'december', text: 'dicembre' }
      ]}
    />
  )
}

MonthSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default MonthSelect
