import React from 'react'
import PropTypes from 'prop-types'
import { Menu, Icon } from 'semantic-ui-react'
import styles from './StationDetailMenu.module.scss'
import history from '../../history'
import config from '../../Config'
import StationPropTypes from '../../Types/Station'

const StationDetailMenu = ({ station, active }) => {
  const goto = section => () => {
    history.push(
      config.urls['station' + section].replace(':id', station.id)
    )
  }

  return (
    <Menu stackable size='massive' inverted fluid widths={4} className={styles.nav}>
      <Menu.Item
        color='blue'
        active={active === 'home'}
        onClick={goto('Home')}
      >
        <Icon name='camera' />
        <span>{station.name}</span>
      </Menu.Item>
      <Menu.Item
        color='blue'
        active={active === 'segmentation'}
        onClick={goto('Segmentation')}
      >
        <Icon name='tags' />
        <span>Segmentation</span>
      </Menu.Item>
      <Menu.Item
        color='blue'
        active={active === 'crescita'}
        onClick={goto('Growth')}
      >
        <Icon name='chart line' />
        <span>Tasso di crescita</span>
      </Menu.Item>
      <Menu.Item
        color='blue'
        active={active === 'trespassing'}
        onClick={goto('Trespassing')}
      >
        <Icon name='object ungroup outline' />
        <span>No trespassing zone</span>
      </Menu.Item>
    </Menu>
  )
}

StationDetailMenu.propTypes = {
  station: StationPropTypes,
  active: PropTypes.string.isRequired
}

export default StationDetailMenu
