import {
  actionCreator,
  requestBlueprint,
  successBlueprint,
  failureBlueprint,
  requestStateBlueprint
} from './Utils'
import { RESET } from './Root'

// Types
export const SEGMENTATION_REQUEST = 'SEGMENTATION_REQUEST'
export const SEGMENTATION_SUCCESS = 'SEGMENTATION_SUCCESS'
export const SEGMENTATION_FAILURE = 'SEGMENTATION_FAILURE'

export const SEGMENTATION_WEIGHTS_REQUEST = 'SEGMENTATION_WEIGHTS_REQUEST'
export const SEGMENTATION_WEIGHTS_SUCCESS = 'SEGMENTATION_WEIGHTS_SUCCESS'
export const SEGMENTATION_WEIGHTS_FAILURE = 'SEGMENTATION_WEIGHTS_FAILURE'

// Actions
export default {
  // login
  segmentationRequest: actionCreator(SEGMENTATION_REQUEST),
  segmentationSuccess: actionCreator(SEGMENTATION_SUCCESS),
  segmentationFailure: actionCreator(SEGMENTATION_FAILURE),
  segmentationWeightsRequest: actionCreator(SEGMENTATION_WEIGHTS_REQUEST),
  segmentationWeightsSuccess: actionCreator(SEGMENTATION_WEIGHTS_SUCCESS),
  segmentationWeightsFailure: actionCreator(SEGMENTATION_WEIGHTS_FAILURE)
}

// Initial state
export const INITIAL_STATE = {
  ...requestStateBlueprint,
  data: {}
}

// Reducers
const request = state => Object.assign({}, state, { ...requestBlueprint })

// login
export const segmentationSuccess = (state, { stationId, data }) => {
  const prevStationData = state.data[stationId] || {}
  return Object.assign({}, state, {
    ...successBlueprint,
    error: false,
    errorCode: null,
    errorMessage: null,
    data: { ...state.data, [stationId]: { ...prevStationData, image: data } }
  })
}

export const segmentationWeightsSuccess = (state, { stationId, data }) => {
  const prevStationData = state.data[stationId] || {}
  return Object.assign({}, state, {
    ...successBlueprint,
    error: false,
    errorCode: null,
    errorMessage: null,
    data: { ...state.data, [stationId]: { ...prevStationData, segmentation: data } }
  })
}

const segmentationFailure = (state, { code, message }) => {
  return Object.assign({}, state, {
    ...failureBlueprint,
    isAuthenticated: false,
    error: true,
    errorCode: code,
    errorMessage: message
  })
}

export function reducer (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case SEGMENTATION_REQUEST:
      return request(state)
    case SEGMENTATION_SUCCESS:
      return segmentationSuccess(state, payload)
    case SEGMENTATION_FAILURE:
      return segmentationFailure(state, payload)
    case SEGMENTATION_WEIGHTS_REQUEST:
      return request(state)
    case SEGMENTATION_WEIGHTS_SUCCESS:
      return segmentationWeightsSuccess(state, payload)
    case SEGMENTATION_WEIGHTS_FAILURE:
      return segmentationFailure(state, payload)
    case RESET:
      return INITIAL_STATE
    default:
      return state
  }
}
