import { call, put, select } from 'redux-saga/effects'
import StartupActions from '../Redux/Startup'
import AuthActions from '../Redux/Auth'
import { resetStore } from '../Redux/Root'
import history from '../history'
import config from '../Config'

export const selectAuthToken = state => state.auth.token

// attempts to login
export function * login (api, { payload }) {
  // request
  const response = yield call(api.login, payload.username, payload.password)

  // success?
  if (response.ok) {
    // set token in the api requests header
    yield call(api.setAuthToken, response.data.token)
    // store auth data in redux store
    yield put(AuthActions.loginSuccess(response.data))
    yield put(AuthActions.whoamiRequest())
  } else {
    yield put(AuthActions.loginFailure({ code: response.status, message: 'Wrong username or password' }))
  }
}

export function * logout () {
  yield put(resetStore())
  yield history.push(config.urls.login)
}

// whoami?
export function * whoami ({ api, dispatch }) {
  // request
  const response = yield call(api.whoami)

  // success?
  if (response.ok) {
    // store whoami data in redux store
    yield put(AuthActions.whoamiSuccess(response.data))
    const isAuthenticated = yield select(state => state.auth.isAuthenticated)
    const startupComplete = yield select(state => state.startup.complete)
    if (isAuthenticated) {
      // goto page user was trying to access, if any
      let redirectUrl
      try {
        redirectUrl = yield select(
          state => state.router.location.state.from.pathname
        )
      } catch (e) {
        redirectUrl = config.urls.home
      }
      history.push(redirectUrl)
    }
    if (!startupComplete) {
      yield put(StartupActions.startupComplete())
    }
  } else {
    yield put(StartupActions.startupComplete())
  }
}
