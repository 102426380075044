import React from 'react'
import styles from './Footer.module.scss'

/**
 * Footer Component
 * Visible in all pages
 */
const Footer = props => {
  return (
    <footer className={styles.footer}>
      VALE by{' '}
      <a className={styles.link} href='http://www.waterview.it'>
        WaterView
      </a>
    </footer>
  )
}

export default Footer
