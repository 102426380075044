import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

const Startup = () => (
  <Dimmer active>
    <Loader content='Caricamento...' />
  </Dimmer>
)

export default Startup
