import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styles from './Navbar.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Menu, Image, Dropdown, Icon, Responsive } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Logo from '../../Assets/img/logo.svg'
import AuthActions from '../../Redux/Auth'
import config from '../../Config'

const Navbar = props => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user.username)
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false)

  const openMobileMenu = useCallback(() => setMobileMenuIsOpen(true), [])
  const closeMobileMenu = useCallback(() => setMobileMenuIsOpen(false), [])
  const logout = useCallback(() => dispatch(AuthActions.logout()), [dispatch])

  return (
    <>
      <Menu className={styles.navbar} key='navbar'>
        <Menu.Item className={styles.item}>
          <Link to={config.urls.home}>
            <Image className={styles.logo} src={Logo} />
          </Link>
        </Menu.Item>
        <Responsive
          minWidth={Responsive.onlyTablet.minWidth}
          as={Menu.Menu}
          position='right'
        >
          <Menu.Item
            link
            className={styles.item}
            key='stations-menu-voice'
            onClick={() => props.onVoiceClick('stations')}
          >
            Stazioni
          </Menu.Item>
          <Menu.Item key='user-menu-voice' className={styles.item}>
            <Dropdown
              trigger={<Icon name='user' className={styles.userIcon} />}
            >
              <Dropdown.Menu>
                <Dropdown.Header
                  content={user}
                  className={styles.dropdownHeader}
                />
                <Dropdown.Item icon='log out' text='Esci' onClick={logout} />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </Responsive>
        <Responsive {...Responsive.onlyMobile} as={Menu.Menu} position='right'>
          <Menu.Item onClick={() => {}} className={styles.item}>
            <Icon
              name={mobileMenuIsOpen ? 'remove' : 'plus'}
              className={styles.menuIcon}
              link
              onClick={mobileMenuIsOpen ? closeMobileMenu : openMobileMenu}
            />
          </Menu.Item>
        </Responsive>
      </Menu>
      {mobileMenuIsOpen && (
        <Responsive
          {...Responsive.onlyMobile}
          as={Menu}
          vertical
          fluid
          key='menu-mobile'
          style={{ margin: 0, borderRadius: 0 }}
        >
          <Menu.Item key='user-menu-voice' className={styles.itemMobile}>
            <Icon
              name='log out'
              className={styles.logoutIcon}
              link
              onClick={logout}
            />
            {user}
          </Menu.Item>
          <Menu.Item
            link
            className={styles.itemMobile}
            onClick={() => props.onVoiceClick('stations')}
            key='stations-menu-voice'
          >
            Stazioni
          </Menu.Item>
        </Responsive>
      )}
    </>
  )
}

Navbar.defaultProps = {}
Navbar.propTypes = {
  onVoiceClick: PropTypes.func.isRequired
}

export default Navbar
