import React from 'react'
import { Grid } from 'semantic-ui-react'
import StationPropTypes from '../../Types/Station'
import moment from 'moment'
import styles from './StationSidebarHeader.module.scss'

const StationSidebarHeader = ({ station }) => {
  const now = moment()
  return (
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column verticalAlign='middle' textAlign='left'>
          <h3 className={styles.title}>{station.name}</h3>
        </Grid.Column>
        <Grid.Column textAlign='right'>
          <time className={styles.date}>
            <span className={styles.day}>{now.format('DD')}</span>
            <div>
              <div className={styles.month}>{now.format('MMM')}</div>
              <div className={styles.year}>{now.format('YYYY')}</div>
            </div>
          </time>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column textAlign='left'>
          {station.address ? station.address : `${station.location.latitude}, ${station.location.longitude}`}
        </Grid.Column>
        <Grid.Column textAlign='right'>
          <time>
            <span>{now.format('HH:mm')}</span>
          </time>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

StationSidebarHeader.propTypes = {
  station: StationPropTypes
}

export default StationSidebarHeader
