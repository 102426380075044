import { call, put } from 'redux-saga/effects'
import StationsActions from '../Redux/Stations'
import { toast } from 'react-toastify'
import { getResponseStringError } from '../Utils/Request'

export function * fetchStations (api) {
  // request
  const response = yield call(api.stations)

  // success?
  if (response.ok) {
    yield put(StationsActions.stationsSuccess(response.data))
  } else {
    toast(
      'Si è verificato un errore nella richiesta delle stazioni: ' +
        getResponseStringError(response),
      { type: 'error' }
    )
    yield put(
      StationsActions.stationsFailure({
        code: response.status,
        message: getResponseStringError(response)
      })
    )
  }
}
