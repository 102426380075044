import React from 'react'
import PropTypes from 'prop-types'
import StationPropTypes from '../../Types/Station'
import styles from './StationDetail.module.scss'
import StationSidebarHeader from '../StationSidebarHeader'

export const SDContainer = props => <div className={styles.container}>{props.children}</div>
SDContainer.propTypes = {
  children: PropTypes.object
}

export const SDRow = props => <div className={styles.row}>{props.children}</div>
SDRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export const SDSidebar = props => (
  <div className={styles.sidebar}>
    <section>
      <StationSidebarHeader station={props.station} />
      {props.children}
    </section>
  </div>
)
SDSidebar.propTypes = {
  station: StationPropTypes,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export const SDContent = props => <div className={styles.content}>{props.children}</div>
SDContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}
