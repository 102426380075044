import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { withLoader } from '../../HOC/Loader'
import {
  useLoadScript,
  GoogleMap,
  Marker,
  MarkerClusterer
} from '@react-google-maps/api'
import { Container } from 'semantic-ui-react'
import StationsTable from '../StationsTable'
import MarkerIcon from '../../Assets/img/marker.png'
import config from '../../Config'

const Map = props => {
  const stations = useSelector(state => state.stations.data)
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleMapsApiKey
  })
  const handleMarkerClick = useCallback(station => props.onMarkerClick(station), [props])

  const renderMap = () => {
    const fitBounds = map => {
      const bounds = new window.google.maps.LatLngBounds()
      stations.forEach(s => {
        bounds.extend(new window.google.maps.LatLng(
          s.location.latitude,
          s.location.longitude
        ))
      })
      map.fitBounds(bounds)

      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        map.setZoom(10) // zoom out if stations are too near
      }
    }

    return (
      <GoogleMap
        className='LIPPA'
        zoom={14}
        center={{
          lat: 45,
          lng: 7
        }}
        onLoad={fitBounds}
        mapContainerStyle={{ position: 'absolute', top: 0, bottom: 0, width: '100%' }}
      >
        <MarkerClusterer
          options={{
            imagePath:
              'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
          }}
        >
          {clusterer =>
            stations
              .filter(s => s.location)
              .map((s, i) => (
                <Marker
                  key={i}
                  icon={MarkerIcon}
                  position={{
                    lat: s.location.latitude,
                    lng: s.location.longitude
                  }}
                  clusterer={clusterer}
                  onClick={() => handleMarkerClick(s)}
                />
              ))}
        </MarkerClusterer>
      </GoogleMap>
    )
  }

  if (loadError) {
    // fallback to stations table
    return <Container style={{ margin: '2rem 0' }}><StationsTable /></Container>
  }

  return withLoader(renderMap, !isLoaded || !stations.length)
}

Map.propTypes = {
  onMarkerClick: PropTypes.func.isRequired
}

export default Map
