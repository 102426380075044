import {
  actionCreator,
  requestBlueprint,
  successBlueprint,
  failureBlueprint,
  requestStateBlueprint
} from './Utils'
import { RESET } from './Root'

// Types
export const GROWTH_RATE_REQUEST = 'GROWTH_RATE_REQUEST'
export const GROWTH_RATE_SUCCESS = 'GROWTH_RATE_SUCCESS'
export const GROWTH_RATE_FAILURE = 'GROWTH_RATE_FAILURE'

export const GROWTH_RATE_LEGEND_REQUEST = 'GROWTH_RATE_LEGEND_REQUEST'
export const GROWTH_RATE_LEGEND_SUCCESS = 'GROWTH_RATE_LEGEND_SUCCESS'
export const GROWTH_RATE_LEGEND_FAILURE = 'GROWTH_RATE_LEGEND_FAILURE'

// Actions
export default {
  // login
  growthRateRequest: actionCreator(GROWTH_RATE_REQUEST),
  growthRateSuccess: actionCreator(GROWTH_RATE_SUCCESS),
  growthRateFailure: actionCreator(GROWTH_RATE_FAILURE),
  growthRateLegendRequest: actionCreator(GROWTH_RATE_LEGEND_REQUEST),
  growthRateLegendSuccess: actionCreator(GROWTH_RATE_LEGEND_SUCCESS),
  growthRateLegendFailure: actionCreator(GROWTH_RATE_LEGEND_FAILURE)
}

// Initial state
export const INITIAL_STATE = {
  ...requestStateBlueprint,
  data: {}
}

// Reducers
const request = state => Object.assign({}, state, { ...requestBlueprint })

// login
export const growthRateSuccess = (state, { stationId, data }) => {
  const prevStationData = state.data[stationId] || {}
  return Object.assign({}, state, {
    ...successBlueprint,
    error: false,
    errorCode: null,
    errorMessage: null,
    data: { ...state.data, [stationId]: { ...prevStationData, image: data } }
  })
}

export const growthRateLegendSuccess = (state, { stationId, data }) => {
  const prevStationData = state.data[stationId] || {}
  return Object.assign({}, state, {
    ...successBlueprint,
    error: false,
    errorCode: null,
    errorMessage: null,
    data: { ...state.data, [stationId]: { ...prevStationData, legend: data } }
  })
}

const growthRateFailure = (state, { code, message }) => {
  return Object.assign({}, state, {
    ...failureBlueprint,
    isAuthenticated: false,
    error: true,
    errorCode: code,
    errorMessage: message
  })
}

export function reducer (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case GROWTH_RATE_REQUEST:
      return request(state)
    case GROWTH_RATE_SUCCESS:
      return growthRateSuccess(state, payload)
    case GROWTH_RATE_FAILURE:
      return growthRateFailure(state, payload)
    case GROWTH_RATE_LEGEND_REQUEST:
      return request(state)
    case GROWTH_RATE_LEGEND_SUCCESS:
      return growthRateLegendSuccess(state, payload)
    case GROWTH_RATE_LEGEND_FAILURE:
      return growthRateFailure(state, payload)
    case RESET:
      return INITIAL_STATE
    default:
      return state
  }
}
