import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import StationPropTypes from '../../Types/Station'
import { SDContainer, SDRow, SDSidebar, SDContent } from '../StationDetail'
import { Image, Divider, List, Label } from 'semantic-ui-react'
import { withLoader } from '../../HOC/Loader'
import styles from './StationSegmentation.module.scss'

const StationSegmentation = ({ station }) => {
  const photo = useSelector(state => state.photos.data[station.id])
  const fetching = useSelector(state => state.photos.fetching)
  const overlay = useSelector(state =>
    state.segmentations.data[station.id]
      ? state.segmentations.data[station.id].image
      : null
  )
  const categories = useSelector(state =>
    state.segmentations.data[station.id]
      ? state.segmentations.data[station.id].segmentation
      : []
  )

  return (
    <SDContainer>
      <SDRow>
        <SDSidebar station={station}>
          <Divider className={styles.divider} />
          <h3>Categorie</h3>
          {categories && (
            <List divided verticalAlign='middle' className={styles.categoriesList}>
              {categories.map(c => {
                const ctg = Object.keys(c)[0]
                const color = c[ctg].color
                const coverage = c[ctg].coverage
                return (
                  <List.Item key={`item-${ctg}`}>
                    <List.Content floated='right'>
                      <Label size='tiny'>{coverage}%</Label>
                    </List.Content>
                    <List.Content>
                      <span style={{ color: `rgb(${color[0]}, ${color[1]}, ${color[2]})` }}>{ctg}</span>
                    </List.Content>
                  </List.Item>
                )
              })}
            </List>
          )}
        </SDSidebar>
        <SDContent>
          {withLoader(
            <div className={styles.segmentationWrapper}>
              <Image
                fluid
                src={`data:image/jpeg;charset=utf-8;base64,${photo}`}
              />
              {overlay && (
                <Image
                  className={styles.overlay}
                  fluid
                  src={`data:image/jpeg;charset=utf-8;base64,${overlay}`}
                />
              )}
            </div>,
            fetching && !photo
          )}
        </SDContent>
      </SDRow>
    </SDContainer>
  )
}

StationSegmentation.propTypes = {
  station: StationPropTypes
}

export default StationSegmentation
