import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Switch } from 'react-router-dom'
import { PrivateRoute } from '../../Router'
import BaseLayout from '../../Layouts/BaseLayout'
import StationDetailMenu from '../../Components/StationDetailMenu'
import { withLoader } from '../../HOC/Loader'
import StationHome from '../../Components/StationHome'
import StationSegmentation from '../../Components/StationSegmentation'
import StationGrowthRate from '../../Components/StationGrowthRate'
import StationTrespassing from '../../Components/StationTrespassing'
import PhotosActions from '../../Redux/Photos'
import SegmentationsActions from '../../Redux/Segmentations'
import GrowthRatesActions from '../../Redux/GrowthRates'
import CanvasActions from '../../Redux/Canvas'
import config from '../../Config'
import moment from 'moment'
import styles from './StationDetailView.module.scss'

const StationDetailView = props => {
  const stations = useSelector(state => state.stations.data)
  const fetching = useSelector(state => state.stations.fetching)

  let growthRateRef = useSelector(state => state.ui.growthRateRefs[props.match.params.id])
  if (!growthRateRef) {
    growthRateRef = moment().format('YYYY')
  }

  const content = () => {
    const station = stations.filter(s => s.id === props.match.params.id)[0]

    // get current tab from path
    let tab = props.location.pathname.substr(
      props.location.pathname.lastIndexOf('/') + 1
    )
    if (tab === station.id) {
      tab = 'home'
    }

    return (
      <>
        <StationDetailMenu station={station} active={tab} />
        <Switch>
          <PrivateRoute
            exact
            path={config.urls.stationHome.replace(':id', station.id)}
            component={StationHome}
            componentProps={{ station }}
            actions={[
              [
                PhotosActions.photoRequest(station.id),
                state => true // always refresh
              ]
            ]}
          />
          <PrivateRoute
            exact
            path={config.urls.stationSegmentation.replace(':id', station.id)}
            component={StationSegmentation}
            componentProps={{ station }}
            actions={[
              [
                PhotosActions.photoRequest(station.id),
                state => true // always refresh
              ],
              [
                SegmentationsActions.segmentationRequest(station.id),
                state => true // always refresh
              ],
              [
                SegmentationsActions.segmentationWeightsRequest(station.id),
                state => true // always refresh
              ]
            ]}
          />
          <PrivateRoute
            exact
            path={config.urls.stationGrowth.replace(':id', station.id)}
            component={StationGrowthRate}
            componentProps={{ station }}
            actions={[
              [
                PhotosActions.photoRequest(station.id),
                state => true // always refresh
              ],
              [
                GrowthRatesActions.growthRateRequest({ stationId: station.id, growthRateRef }),
                state => true // always refresh
              ]
            ]}
          />
          <PrivateRoute
            exact
            path={config.urls.stationTrespassing.replace(':id', station.id)}
            component={StationTrespassing}
            componentProps={{ station }}
            actions={[
              [
                PhotosActions.photoRequest(station.id),
                state => true // always refresh
              ],
              [
                CanvasActions.resetCanvas(),
                state => true // always refresh
              ]
            ]}
          />
        </Switch>
      </>
    )
  }

  return (
    <BaseLayout wrapperStyle={styles.homeView}>
      {withLoader(content, fetching)}
    </BaseLayout>
  )
}

StationDetailView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
}

export default StationDetailView
