import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Form, Modal, Button, Icon, Header } from 'semantic-ui-react'

import styles from './LoginForm.module.scss'

const LoginForm = props => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const setField = field => (e, { value }) => {
    switch (field) {
      case 'username':
        setUsername(value)
        break
      case 'password':
        setPassword(value)
        break
      default:
        break
    }
  }

  const submit = () => {
    props.onSubmit(username, password)
  }

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <h1>Accedi alla dashboard</h1>
        <Form>
          <Form.Field>
            <label>Username</label>
            <Form.Input
              type='text'
              name='username'
              placeholder='Inserisci il tuo nome utente'
              autoComplete='username'
              onChange={setField('username')}
            />
          </Form.Field>
          <Form.Field>
            <label>Password</label>
            <Form.Input
              type='password'
              name='password'
              placeholder='Inserisci la tua password'
              autoComplete='current-password'
              onChange={setField('password')}
            />
          </Form.Field>
          <Form.Field className={styles.submitRow}>
            <input
              type='submit'
              value='Accedi'
              className='ui primary button form-submit'
              onClick={submit}
            />
          </Form.Field>
        </Form>
      </div>
      <Modal open={props.error} basic size='small'>
        <Header icon='ban' content='Errore di autenticazione' />
        <Modal.Content>
          <p>{props.errorMessage}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' inverted onClick={props.onClear}>
            <Icon name='checkmark' />
            {' '}
            Riprova
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string
}

export default LoginForm
