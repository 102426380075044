import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { actionCreator } from './Utils'
import { reducer as startupReducer } from './Startup'
import { reducer as authReducer } from './Auth'
import { reducer as stationsReducer } from './Stations'
import { reducer as photosReducer } from './Photos'
import { reducer as segmentationsReducer } from './Segmentations'
import { reducer as growthRatesReducer } from './GrowthRates'
import { reducer as uiReducer } from './Ui'
import { reducer as canvasReducer } from './Canvas'

// reset type
export const RESET = 'RESET'
// reset action
export const resetStore = actionCreator(RESET)

export default (history) => combineReducers({
  router: connectRouter(history),
  startup: startupReducer,
  auth: authReducer,
  stations: stationsReducer,
  photos: photosReducer,
  segmentations: segmentationsReducer,
  growthRates: growthRatesReducer,
  ui: uiReducer,
  canvas: canvasReducer
})
