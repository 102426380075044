import React from 'react'
import PropTypes from 'prop-types'
import StationsTable from '../StationsTable'
import { Modal } from 'semantic-ui-react'

const StationsModal = props => (
  <Modal open={props.isOpen} onClose={props.onClose} closeIcon>
    <Modal.Header>
      Stazioni
    </Modal.Header>
    <Modal.Content>
      <StationsTable onStationClick={props.onClose} />
    </Modal.Content>
  </Modal>
)

StationsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default StationsModal
