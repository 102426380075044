import React from 'react'
import LoginForm from '../../Forms/LoginForm'
import Welcome from '../../Components/Welcome'
import Footer from '../../Components/Footer'
import { useDispatch, useSelector } from 'react-redux'
import AuthActions from '../../Redux/Auth'
import styles from './LoginView.module.scss'

const LoginView = props => {
  const dispatch = useDispatch()
  const error = useSelector(state => state.auth.error)
  const errorMessage = useSelector(state => state.auth.errorMessage)

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <Welcome />
        </div>
        <div className={styles.col}>
          <LoginForm
            onSubmit={(username, password) =>
              dispatch(AuthActions.loginRequest({ username, password }))}
            onClear={() => dispatch(AuthActions.reset())}
            {...{ error, errorMessage }}
          />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default LoginView
